body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color:#0d47a1 ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*nav div ul li a.active {
  background-color: green;
}*/

.tabs .indicator{
    background-color: #26a69a !important;
}

#home-nav, #app-nav{
	box-shadow: none !important;
}

.flex-center{
	display:'flex';
	justify-content:'center'; 
	align-items:'center';
}

.j-align-center{
	justify-content: 'center'
}

.d-flex{
	display: flex;
}

.f-align-center{
	align-items: center;
}

.absolute{
	position: absolute;
	top:120 !important;
	z-index: 9;
}

.green-border{
	border: 2px solid teal !important;
}

.green-border-light{
	border: 1px solid teal !important;
}

.b-5{
	border-radius: 5px !important;
}

.b-dotted{
	border-width: 1px;
	border-style: dashed !important;
}

.relative{
	position: relative;
}

.f-wt-500{
	font-weight: 500;
}

.f-12{
	font-size: 12px;
}

.f-16{
	font-size: 16px;
}

.m-0{
	margin: 0px !important;
}

.transparent{
	background-color: transparent;
}

.w-100p, .KIWI-button{
	width:100% !important;
}


.m-top-50{
	margin-top: 50px;
}

.f-left{
	float:left ;
}



.p-left40, #country_01 div button,  #country_02 div button, #city_01 div button, #city_01_enquiry div button, #country_better_rate_currency_01 div button,  #country_better_rate_city_01 div button, #country_05 div button, #country_06 div button, #country_07 div button, #country_08 div button, #city_06 div button, #city_07 div button {
	padding-left: 40px;
}

#country_02 div div input, #country_04 div div input {
	/*width:100%;*/
	margin: 0px !important;
	border-bottom: 0px !important;
}

#country_02 div div div, .KIWI-option-list, #country_04 div div div{
	/*width:100%;*/
	z-index: 100;
}

.KIWI-button{
	background-color: white;
	font-size: 16px;
}

.KIWI-option {
  font-size: 16px !important;
}

.KIWI-option-list{
	max-height: 200px;
	overflow: scroll;
	width: 250px;
	overflow-x: hidden;
}

.flag-position{
	position: absolute;
	top:30px;
	left:5px;
}



.flag-select{
	width: 100%;
} 

#select_flag_button{
	width: 80%;
	background-color: white;
}

.t-left{
	text-align: left;
}

.t-center{
	text-align: center;
}

.t-right{
	text-align: right;
}

.p-bottom-5{
	padding-bottom: 5px;
}

.modal { max-height: 80% !important }

.p-20{
	padding: 20px !important;
}


#rateUpload{
	border:0px !important;
}

#reviewContainer div .slick-prev:before, #reviewContainer div .slick-next:before, #rateContainer div .slick-prev:before, #rateContainer div .slick-next:before{
	color: #bdbdbd !important;
}
.slick-slide div div {
    outline: none !important
}


#multi_country_select_01 div div input[type=text]:not(.browser-default):focus:not([readonly]) {
	    box-shadow: 0 0px 0 0 #26a69a;

}

#multi_country_select_01 div div input{
	height: 30px;
}

#multi_country_select_01 div{
	border: 0px !important;
	border-radius: 0px !important;
}


#startDate_01, #startDate_02{
	background: white;
	height: 35px;

}


.z-depth-1, nav, .card-panel, .card, .toast, .btn, .btn-large, .btn-small, .btn-floating, .dropdown-content, .collapsible, .sidenav{
	box-shadow: none !important;
}

.sc-cxFLnm svg{
	color: white !important
}

.jpnrBA{
	color: #0d47a1 !important;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

	.banner-form{
		    display: flex;
		    justify-content: center;
		    align-items: center;
		    flex-direction: column;
		    height: 1000px;
	}
	.slick-slider{
		height: 300px;
		margin-left: 30px;
		margin-right: 30px;
	}

	.r-top-10{
		margin-top:10px;
	}
	.p-right-150{
		padding-right: 10px;
	}
	#reviewContainer{
		height: 350px
	}

	#reviewContainer .slick-slider{
		height: 340px;
		padding-top: 20px;
	}

	.f-10-small{
		font-size: 10px;
	}

		.left-rate-button{
		left: 35%;
	}

	.formContainer{
		margin-top: 320px
	}

	.no-order{
		height: 200px;
	}
 }
	
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.banner-form{
		    display: flex;
		    justify-content: center;
		    align-items: center;
		    height: 500px;
	}
	.slick-slider{
		height: 350px;
		margin-left: 30px;
		margin-right: 30px;
	}
	.p-right-150{
		padding-right: 50px;
	}
	#reviewContainer{
		height: 150px
	}
	#reviewContainer .slick-slider{
		height: 200px;
		padding-top: 20px;
	}

	.f-10-small{
		font-size: 12px;
	}

		.left-rate-button{
		left: 45%;
	}

	.no-order{
		height: 300px;
	}

 }

/* Medium devices (landscape tablets, 768px and up) 
@media only screen and (min-width: 768px) { }*/

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

	.banner-form{
		    display: flex;
		    justify-content: center;
		    align-items: center;
		    height: 500px;
		    
	}
	.slick-slider{
		height: 250px;
		margin-left: 30px;
		margin-right: 30px;
	}
	.p-right-150{
		padding-right: 150px;
	}
	#reviewContainer{
		height: 150px
	}
	#reviewContainer .slick-slider{
		height: 150px;
		padding-top: 20px;
	}

	.left-rate-button{
		left: 45%;
	}

	.no-order{
		height: 400px;
	}

 }

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) { 

	.banner-form{
		    display: flex;
		    justify-content: center;
		    align-items: center;
		    height: 500px;
	}

	.slick-slider{
		height: 250px;
		margin-left: 30px;
		margin-right: 30px;
	}
	.p-right-150{
		padding-right: 150px;
	}

	#reviewContainer{
		height: 150px
	}
	#reviewContainer .slick-slider{
		height: 150px;
		padding-top: 20px;
	}

	.left-rate-button{
		left: 45%;
	}

	.no-order{
		height: 400px;
	}
}


.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{
	border: 1px solid teal !important;
	background: white  !important;
}

table.dataTable thead th, table.dataTable thead td{
	border-bottom: 1px solid teal !important;
}

table.dataTable.no-footer{
	border-bottom: none !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover{
	background-color: teal !important;
    background: teal !important;
}

#table_id_filter, #table_id_admin_orders_filter, #table_id_admin_orders_tab_filter {
	float: left;
}

#table_id_filter label input, #table_id_admin_orders_filter label input, #table_id_admin_orders_tab_filter label input {
	height: 25px;
	border: 1px solid teal !important;
}

input:not([type]), input[type=text]:not(.browser-default), input[type=password]:not(.browser-default), input[type=email]:not(.browser-default), input[type=url]:not(.browser-default), input[type=time]:not(.browser-default), input[type=date]:not(.browser-default), input[type=datetime]:not(.browser-default), input[type=datetime-local]:not(.browser-default), input[type=tel]:not(.browser-default), input[type=number]:not(.browser-default), input[type=search]:not(.browser-default), textarea.materialize-textarea {

	border: 1px solid teal !important;
}